import { FC } from "react";
import { TabPanel, Tabs } from "../../../../TrueUI";
import { ImportInformationDto } from "../../../../../dtos/import-information-dto";
import ClassCodesRatesImportResultTable from "./ClassCodesRatesImportResultTable";
import ClassCodesRatesImportErrorsTable from "./ClassCodesRatesImportErrorsTable";

type ClassCodesRatesImportTablesProps = {
  importInformation?: ImportInformationDto | null;
};
const ClassCodesRatesImportTables: FC<ClassCodesRatesImportTablesProps> = ({
  importInformation,
}) => {
  const { tableErrorsData } = { ...importInformation };

  const showErrorTab = (tableErrorsData?.data?.length ?? 0) > 0;
  const showResultsTab = (importInformation?.tableData?.data?.length ?? 0) > 0;
  return (
    <Tabs name={"import-results-table"}>
      {showResultsTab ? (
        <TabPanel title="Results" key={"results-import"}>
          <ClassCodesRatesImportResultTable
            importInformation={importInformation}
          />
        </TabPanel>
      ) : null}
      {showErrorTab ? (
        <TabPanel title="Errors" key={"errors-table"}>
          <ClassCodesRatesImportErrorsTable tableErrorsData={tableErrorsData} />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};

export default ClassCodesRatesImportTables;
