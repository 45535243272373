import { atom } from "recoil";

const AdminProgramAtom = atom<any>({
  key: "AdminProgramAtom",
  default: {
    configuration: null,
    programName: "",
  },
});

const AdminProgramErrorAtom = atom<any>({
  key: "AdminProgramErrorAtom",
  default: {
    errorDetails: null,
  },
});

const ShowImportRatesFormAtom = atom<any>({
  key: "ShowImportRatesFormAtom",
  default: false,
});

export { AdminProgramAtom, AdminProgramErrorAtom, ShowImportRatesFormAtom };
