import { FC, useEffect, useState } from "react";
import { BaseGridProperties } from "../../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../../BaseGrid/BaseGrid";
import { ImportInformationDto } from "../../../../../dtos/import-information-dto";
import { useApiPost } from "../../../../../hooks";
import { SaveImportInformationDto } from "../../../../../dtos/save-import-information-dto";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { useSetRecoilState } from "recoil";
import { ShowImportRatesFormAtom } from "../../ProgramAtoms";

type ClassCodesRatesImportResultTableProps = {
  importInformation?: ImportInformationDto | null;
};

const ClassCodesRatesImportResultTable: FC<
  ClassCodesRatesImportResultTableProps
> = ({ importInformation }) => {
  const setIsImportFormOpen = useSetRecoilState(ShowImportRatesFormAtom);
  const [dialogInfo, setDialogInfo] = useState<{
    dialogText?: string;
    isDialogOpen?: boolean;
  }>({ dialogText: "", isDialogOpen: false });

  const saveInformation: SaveImportInformationDto = {
    formInformation: importInformation?.formInformation,
    classCodeRateRecords: importInformation?.classCodeRateRecords,
    classCodeRecords: importInformation?.classCodeRecords,
  };
  const { responsePost: responseSaveImport, dispatchPost: dispatchSaveImport } =
    useApiPost(
      `api/AdminTools/RateTables/SaveImportClassCodesRates`,
      saveInformation
    );

  useEffect(() => {
    if (isAPITotallyComplete(responseSaveImport)) {
      if (responseSaveImport?.responseData !== "success") {
        setDialogInfo({
          dialogText: `There was an error while saving. 
            <br/> <br/> 
            <b>Error: </b> ${responseSaveImport?.responseData}`,
          isDialogOpen: true,
        });
      } else {
        setDialogInfo({
          dialogText: "The class code rates have been successfully imported.",
          isDialogOpen: true,
        });
      }
    }
  }, [responseSaveImport]);

  const TABLE_NAME = "result-import-table";

  const tableConfiguration: BaseGridProperties = {
    name: TABLE_NAME,
    columnsAndData: importInformation?.tableData,
    columnOptions: [
      { fieldName: "ClassCode", width: 12 },
      { fieldName: "BaseRate", width: 13, align: "right" },
      { fieldName: "Rate", width: 13, align: "right" },
      { fieldName: "ELR", width: 13, align: "right" },
      { fieldName: "DRatio", width: 13, align: "right" },
      { fieldName: "Hazard", width: 12 },
      { fieldName: "Industry", width: 12 },
      { fieldName: "MinimumPremium", width: 12, align: "right" },
    ],
    toolbarOptions: {
      showAddButton: true,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
      addButtonText: "Import",
    },
    events: { addEventOverrideCallback: () => dispatchSaveImport() },
  };

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ height: "100%" }}>
      <BaseGrid name={TABLE_NAME} />
      <DialogConfirmation
        open={dialogInfo?.isDialogOpen}
        dialogDescriptionText={dialogInfo?.dialogText}
        onOptionYesEvent={() => {
          setDialogInfo({ dialogText: "", isDialogOpen: false });
          if (responseSaveImport?.responseData === "success") {
            setIsImportFormOpen(false);
          }
        }}
        optionYesOverrideLabel="OK"
      />
    </div>
  );
};
export default ClassCodesRatesImportResultTable;
