import { FC, useEffect, useState } from "react";
import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import { PolicyProducerBlob } from "../../../../../dtos/policy-producer-blob";
import { useApiPost } from "../../../../../hooks";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import { conditionHasValue } from "../../../../../utilities/conditionalSupportFunctions";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { BaseTable2 } from "../../../../TrueUI";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { OPTION_COLUMN_FIELD_NAME } from "../../../../TrueUI/Tables/TableConstants";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { filterDefaultRatesByPolicyDates } from "../../EndorsementInformation/EndorsementProducer/ProducerChangeUtil";
import { AgencyInformationTableProps } from "../../PolicyQuoteForm/PolicyQuoteTypes";
import { getPolicyQuote } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { updatePolicyQuote } from "../../updatesPolicyQuoteFunctions";
import {
  onAgencyNameChanged,
  onAgentChange,
  onCommissionOverrideChange,
  onLocationChange,
  onPrimaryChange,
} from "./AgencyInformationTableComputeFunctions";
import {
  agencyInformationTableDefaultData,
  getAgencyInformationTableColumns,
} from "./AgencyInformationTableConfiguration";
import {
  getProducersAsTableData,
  getProducersByTableData,
} from "./AgencyInformationTableUtils";
import { BaseTableDisplayOptions } from "../../../../../dtos/base-table-display-options";
import { getDateObject } from "../../../../../utilities/dateFunctions";
import { usePolicyQuoteTriggerComponent } from "../../hooks/usePolicyQuoteTriggerComponent";

const AgencyInformationTable: FC<AgencyInformationTableProps> = ({
  insuredId,
  tabKey,
  agencyList,
  readOnly = false,
}) => {
  const tableName = `agency_information_table_${insuredId}`;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [producers, setProducers] = useState<PolicyProducerBlob[]>([]);
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const { responsePost, dispatchPost } = useApiPost<PolicyProducerBlob[]>(
    "api/QuotePolicy/UpdateProducers",
    producers
  );

  const getProducerDefaultRatesList = () => {
    const atomValue = getAtom();
    const quote = getPolicyQuote(atomValue);
    const policyType = quote?.policyType?.value ?? 0;
    const allRates = quote?.configuration?.defaultRatesList;
    const defaultRates = filterDefaultRatesByPolicyDates(
      allRates,
      quote?.effectiveDate,
      quote?.expirationDate
    );

    return { policyType, defaultRates };
  };

  const getDates = () => {
    const atomValue = getAtom();
    const effectiveDate =
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.effectiveDate;
    const expirationDate =
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.expirationDate;
    return {
      effectiveDate: effectiveDate ?? new Date(),
      expirationDate: expirationDate ?? new Date(),
    };
  };

  const getAgencyListFilteredAsBaseTableDisplayOptions = () => {
    const { effectiveDate, expirationDate } = getDates();

    return agencyList
      .filter((agency) => {
        if (
          getDateObject(agency.effectiveDate) <= getDateObject(effectiveDate) &&
          getDateObject(agency.expirationDate) > getDateObject(expirationDate)
        ) {
          return true;
        }
        return null;
      })
      .map((agency) => {
        return {
          text: agency.agencyName,
          value: agency.agencyId.toString(),
        } as BaseTableDisplayOptions;
      });
  };

  const updateProducersByTableChanged = (
    data: string[][],
    columns: BaseTableColumn[]
  ) => {
    const { policyType, defaultRates } = getProducerDefaultRatesList();
    const newProducers = getProducersByTableData(
      data,
      columns,
      policyType ?? 0,
      defaultRates
    );
    if (newProducers.length > 0) {
      setProducers(newProducers);
    }
  };

  const deleteProducerByUniqueKey = (uniqueKey: string) => {
    const updatedProducers = producers.filter(
      (producer) => producer.uniqueKey !== uniqueKey
    );

    setProducers(updatedProducers);
  };

  useEffect(() => {
    if (producers.length > 0) {
      dispatchPost();
    }
  }, [producers]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuote(
        atomValue,
        "producers",
        responsePost.axiosResponse?.data ?? []
      );

      setAtom(newAtomValue);
      setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
    }
  }, [responsePost]);

  const getInitialTableData = (): string[][] => {
    const atomValue = getAtom();
    const producers = atomValue?.policyQuoteInformation?.policyQuote?.producers;
    if (conditionHasValue(producers) && (producers?.length ?? 0 > 0)) {
      return getProducersAsTableData(producers ?? [], readOnly);
    }
    return readOnly ? [] : agencyInformationTableDefaultData;
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: getAgencyInformationTableColumns(
        getAgencyListFilteredAsBaseTableDisplayOptions(),
        readOnly
      ),
      data: getInitialTableData(),
    },
    columnOptions: [
      {
        fieldName: "AgencyName",
        computeOnChange: {
          conditionForCell: [
            (options) => onAgencyNameChanged(options, getDates),
          ],
        },
        computeOnInit: !readOnly
          ? {
              conditionForCell: [
                (options) => onAgencyNameChanged(options, getDates),
              ],
            }
          : {},
        width: 25,
        selectMaxHeight: "200px",
      },
      {
        fieldName: "Location",
        computeOnChange: {
          conditionForCell: [(options) => onLocationChange(options, getDates)],
        },
        width: 25,
        selectMaxHeight: "200px",
      },
      {
        fieldName: "Agent",
        computeOnChange: {
          conditionForCell: [(options) => onAgentChange(options)],
        },
        width: 25,
        selectMaxHeight: "200px",
      },
      {
        fieldName: "Primary",
        computeOnChange: {
          conditionForColumn: [(options) => onPrimaryChange(options)],
        },
        width: 10,
      },
      {
        fieldName: "CommissionOverride",
        computeOnChange: {
          conditionForCell: [(options) => onCommissionOverrideChange(options)],
        },
        maxNumericValue: 20,
        minNumericValue: 0,
        decimalScale: 1,
      },
      {
        fieldName: OPTION_COLUMN_FIELD_NAME,
        width: 7,
      },
    ],
    events: {
      onComputeFinish: (data, columns) =>
        updateProducersByTableChanged(data, columns),
      onDeleteRow: ({ hydratedRow }) =>
        deleteProducerByUniqueKey(hydratedRow.UniqueKey),
    },
    advancedOptions: {
      paginate: false,
      isEditMode: !readOnly,
      tableStyle: { height: "auto" },
      optionsColumnConfiguration: {
        isHidden: readOnly,
        optionType: "plus_minus",
        optionalPropertyValueData: [
          {
            property: "UniqueKey",
            value: () => {
              return crypto.randomUUID();
            },
          },
        ],
      },
    },
    toolbarOptions: {
      hideToolbar: true,
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <div
      id="agency-information-table-container"
      style={{ paddingBottom: "30px", paddingLeft: "5px", width: "90%" }}
    >
      <BaseTable2 name={tableName} />
    </div>
  );
};

export default AgencyInformationTable;
