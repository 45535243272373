import { FC } from "react";
import {
  BaseGridColumnsAndDataProperties,
  BaseGridProperties,
} from "../../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../../BaseGrid/BaseGrid";

type ClassCodesRatesImportResultTableProps = {
  tableErrorsData?: BaseGridColumnsAndDataProperties | null | undefined;
};

const ClassCodesRatesImportErrorsTable: FC<
  ClassCodesRatesImportResultTableProps
> = ({ tableErrorsData }) => {
  const TABLE_NAME = "errors-import-table";

  const tableConfiguration: BaseGridProperties = {
    name: TABLE_NAME,
    columnsAndData: tableErrorsData,
    columnOptions: [
      { fieldName: "RowNumber", width: 15 },
      { fieldName: "Message", width: 85 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
  };

  useBaseGrid(tableConfiguration);

  return (
    <div style={{ height: "100%" }}>
      <BaseGrid name={TABLE_NAME} />
    </div>
  );
};
export default ClassCodesRatesImportErrorsTable;
