import { FC, useEffect, useState } from "react";
import { Button, Input } from "../TrueUI";
import { useApiPost } from "../../hooks";

type MailInfo = {
  email: string;
  name: string;
};
const MaferSandBox: FC = () => {
  const [mailInfo, setMailInfo] = useState<MailInfo>({ email: "", name: "" });
  const { responsePost, dispatchPost } = useApiPost(
    `api/email/SendEmailWithTemplate?to=${encodeURIComponent(
      mailInfo.email
    )}&name=${encodeURIComponent(mailInfo.name)}`
  );

  useEffect(() => {
    console.log(responsePost);
  }, [responsePost]);
  return (
    <div
      style={{
        width: "200px",
        margin: "50px",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Input
        id="email-input"
        name="emailInput"
        type="email"
        label="Email address:"
        value={mailInfo.email}
        onChangeRawValue={(value) => setMailInfo({ ...mailInfo, email: value })}
      />
      <Input
        id="name-input"
        name="nameInput"
        type="text"
        label="Name:"
        value={mailInfo.name}
        onChangeRawValue={(value) => setMailInfo({ ...mailInfo, name: value })}
      />
      <Button onClick={() => dispatchPost()}>Send email</Button>
    </div>
  );
};

export default MaferSandBox;
