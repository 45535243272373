import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { TriggerPolicyQuoteUpdateAtom } from "../hooks/usePolicyQuoteTriggerComponent";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { CaptionBold, Font } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  PolicyQuoteHeaderUIProps,
  PolicyQuoteInsuredAsProp,
} from "./PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import style from "./PolicyQuote.module.css";

const defaultPolicyQuoteHeaderUI = {
  policyStatus: "",
  quoteStatus: "",
  policyDates: "",
  policyType: "",
  policyLimits: "",
  agencyName: "",
};

const PolicyQuoteHeader: FC<PolicyQuoteInsuredAsProp> = ({ tabKey }) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const [policyQuoteHeaderUI, setPolicyQuoteHeaderUI] =
    useState<PolicyQuoteHeaderUIProps>(defaultPolicyQuoteHeaderUI);

  const listenerPolicyQuoteHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("policyQuoteHeaderComponent")
  );

  useEffect(() => {
    const atomValue = getAtom();
    const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
    const quoteJSON = atomValue?.policyQuoteInformation?.policyQuote?.quote;
    const primaryProducer = policyJSON?.producers?.find(
      (producer) => producer.policyAgencyPrimary === true
    );

    const newPolicyQuoteHeaderUI: PolicyQuoteHeaderUIProps = {
      policyStatus: policyJSON?.policyStatus?.description ?? "NEW SUBMISSION",
      quoteStatus: quoteJSON?.quoteStatus?.description ?? "NEW",
      policyDates: `${FormattingDate(
        quoteJSON?.effectiveDate
      )} - ${FormattingDate(quoteJSON?.expirationDate)}`,
      policyType: policyJSON?.policyType?.description ?? "",
      policyLimits: quoteJSON?.policyLimits?.displayValue ?? "",
      agencyName: primaryProducer?.agency?.agencyName ?? "",
    };

    setPolicyQuoteHeaderUI(newPolicyQuoteHeaderUI);
  }, [listenerPolicyQuoteHeaderComponent]);

  return (
    <div className={style.policy_quote_header_container}>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Status</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-status">{policyQuoteHeaderUI?.policyStatus}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Quote Status</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="quote-status">{policyQuoteHeaderUI?.quoteStatus}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Dates</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-dates">{policyQuoteHeaderUI?.policyDates}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Type</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-type">{policyQuoteHeaderUI?.policyType}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Policy Limits</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font name="policy-limits">{policyQuoteHeaderUI?.policyLimits}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Agency Name</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font
            display="inline-block"
            maxWidth="300px"
            name="agency-name"
            truncate
            showTooltip
          >
            {policyQuoteHeaderUI.agencyName}
          </Font>
        </div>
      </div>
    </div>
  );
};

export default PolicyQuoteHeader;
