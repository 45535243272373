import { FC, useEffect, useState } from "react";
import {
  EndorsementGeneralProps,
  EndorsementHeaderUIProps,
} from "./EndorsementTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AuditStatusChangedModalAtom,
  TriggerPolicyQuoteUpdateAtom,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { CaptionBold, Font } from "../../../TrueUI";
import style from "./Endorsement.module.css";
import Link from "../../../TrueUI/Typography/Link";

const defaultEndorsementHeaderUI: EndorsementHeaderUIProps = {
  policyStatus: "",
  quoteStatus: "",
  policyDates: "",
  policyType: "",
  policyLimits: "",
  agencyName: "",
  policyId: -1,
  quoteId: -1,
  currentStatus: -1,
};

const EndorsementHeader: FC<EndorsementGeneralProps> = ({ tabKey }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [endorsementHeaderUI, setEndorsementHeaderUI] =
    useState<EndorsementHeaderUIProps>(defaultEndorsementHeaderUI);

  const listenerEndorsementHeaderComponent = useRecoilValue(
    TriggerPolicyQuoteUpdateAtom("endorsementHeaderComponent")
  );

  const setAuditStatusChangedModalAtom = useSetRecoilState(
    AuditStatusChangedModalAtom
  );

  useEffect(() => {
    if (listenerEndorsementHeaderComponent !== null) {
      const atomValue = getAtom();
      const policyQuote = atomValue?.policyQuoteInformation?.policyQuote;
      const quote = atomValue?.policyQuoteInformation?.policyQuote?.quote;
      const primaryProducer = policyQuote?.producers?.find(
        (producer) => producer.policyAgencyPrimary === true
      );

      const newEndorsementHeaderUI: EndorsementHeaderUIProps = {
        policyStatus: policyQuote?.policyStatus?.description ?? "",
        quoteStatus: quote?.quoteStatus?.description ?? "",
        auditStatus:
          policyQuote?.auditConfiguration?.auditValues?.auditStatus.description,
        policyDates: `${FormattingDate(
          quote?.effectiveDate
        )} - ${FormattingDate(quote?.expirationDate)}`,
        policyType: policyQuote?.policyType?.description ?? "",
        policyLimits: quote?.policyLimits?.displayValue ?? "",
        agencyName: primaryProducer?.agency?.agencyName ?? "",
        policyId: policyQuote?.policyID ?? -1,
        quoteId: policyQuote?.quote?.quoteID ?? -1,
        currentStatus:
          policyQuote?.auditConfiguration?.auditValues?.auditStatus.value ?? -1,
      };
      setEndorsementHeaderUI(newEndorsementHeaderUI);
    }
  }, [listenerEndorsementHeaderComponent]);

  return (
    <div className={style.endorsement_header_container}>
      <div className={style.endorsement_header_pair_text_container}>
        <div className={style.endorsement_header_text_container}>
          <CaptionBold>Policy Status</CaptionBold>
        </div>
        <div className={style.endorsement_header_text_container}>
          <Font name="policy-status">{endorsementHeaderUI.policyStatus}</Font>
        </div>
      </div>
      <div className={style.endorsement_header_pair_text_container}>
        <div className={style.endorsement_header_text_container}>
          <CaptionBold>{`${
            endorsementHeaderUI.auditStatus ? "Audit" : "Quote"
          } Status`}</CaptionBold>
        </div>
        <div className={style.endorsement_header_text_container}>
          {endorsementHeaderUI.auditStatus ? (
            <Link
              name="quote-status"
              linkFontType={"SMALL_TITLE"}
              displayValue={endorsementHeaderUI.auditStatus}
              onClick={() =>
                setAuditStatusChangedModalAtom({
                  isOpen: true,
                  isAudit: true,
                  policyId: endorsementHeaderUI.policyId,
                  quoteId: endorsementHeaderUI.quoteId,
                  currentStatus: endorsementHeaderUI.currentStatus,
                })
              }
            />
          ) : (
            <Font name="quote-status">{endorsementHeaderUI.quoteStatus}</Font>
          )}
        </div>
      </div>
      <div className={style.endorsement_header_pair_text_container}>
        <div className={style.endorsement_header_text_container}>
          <CaptionBold>Policy Dates</CaptionBold>
        </div>
        <div className={style.endorsement_header_text_container}>
          <Font name="policy-dates">{endorsementHeaderUI.policyDates}</Font>
        </div>
      </div>
      <div className={style.endorsement_header_pair_text_container}>
        <div className={style.endorsement_header_text_container}>
          <CaptionBold>Type</CaptionBold>
        </div>
        <div className={style.endorsement_header_text_container}>
          <Font name="policy-type">{endorsementHeaderUI.policyType}</Font>
        </div>
      </div>
      <div className={style.endorsement_header_pair_text_container}>
        <div className={style.endorsement_header_text_container}>
          <CaptionBold>Policy Limits</CaptionBold>
        </div>
        <div className={style.endorsement_header_text_container}>
          <Font name="policy-limits">{endorsementHeaderUI.policyLimits}</Font>
        </div>
      </div>
      <div className={style.policy_quote_header_pair_text_container}>
        <div className={style.policy_quote_header_text_container}>
          <CaptionBold>Agency Name</CaptionBold>
        </div>
        <div className={style.policy_quote_header_text_container}>
          <Font
            display="inline-block"
            maxWidth="300px"
            name="agency-name"
            truncate
            showTooltip
          >
            {endorsementHeaderUI?.agencyName}
          </Font>
        </div>
      </div>
    </div>
  );
};

export default EndorsementHeader;
